import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import "react-tabs/style/react-tabs.css";




const Map = () => {

  const { rpdata } = useContext(GlobalDataContext);

  return (
    <div>
      <div className="md:w-[95%] mx-auto mt-[60px]">
        {/* {
          rpdata?.labels?.general?.titleMap ?
            <h2 className="text-center pb-5">
              {rpdata?.labels?.general?.titleMap}
            </h2>
            :
            <h2 className="text-center pb-5">
              We Cover {rpdata?.dbPrincipal?.miles} Miles Around {rpdata?.dbPrincipal?.location?.[0].address}
            </h2>
        } */}

      </div>

      {
        rpdata?.dbPrincipal?.location.length > 1 ?

          <div className="block md:flex space-x-2 space-y-2 md:space-y-0">
            <div className="w-full md:w-1/2 ">
              <h3 className="text-center pb-5 text-3xl text-red-600 font-bold">
                We are located at:  <br/> {rpdata?.dbPrincipal?.location?.[0].address}
              </h3>
              <iframe
                src={rpdata?.dbPrincipal?.location?.[0].url}
                loading="lazy"
                title="Cover Locations"
                className="w-full h-[500px]"
              />
            </div>
            <div className="w-full md:w-1/2 ">
              <h3 className="text-center pb-5 text-3xl text-red-600 font-bold">
                We are located at: <br/> {rpdata?.dbPrincipal?.location?.[1].address}
              </h3>
              <iframe
                src={rpdata?.dbPrincipal?.location?.[1].url}
                loading="lazy"
                title="Cover Locations"
                className="w-full h-[500px]"
              />
            </div>

          </div>
          :
          <iframe
            src={rpdata?.dbPrincipal?.location?.[0].url}
            loading="lazy"
            title="Cover Locations"
            className="w-full h-[500px]"
          />

      }

    </div>
  );
};
export default Map;